var render = function () {
  var _vm$dictionaries, _vm$activeDocument;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['accounting-table', _vm.showDocument ? 'accounting-table--split' : '']
  }, [_c('div', {
    staticClass: "accounting-table__inner"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectedRowsLength > 0 && _vm.showBulkOptions,
      expression: "selectedRowsLength > 0 && showBulkOptions"
    }],
    staticClass: "bulk-options",
    style: "margin-top: ".concat(_vm.bulkOptionsMarginTop, "px;")
  }, [_c('sygni-loader', {
    attrs: {
      "is-loading": _vm.isPaymentStatusLoading || _vm.isAccountingDateLoading || _vm.isCsvExporting
    }
  }), _c('div', {
    staticClass: "bulk-options__close",
    on: {
      "click": _vm.closeBulkOptions
    }
  }, [_c('div', {
    staticClass: "bulk-options__close-mask"
  }), _c('img', {
    attrs: {
      "src": _vm._f("getIcon")('X'),
      "alt": "Close"
    }
  })]), _c('div', {
    staticClass: "bulk-options__row"
  }, [_c('span', {
    staticClass: "bulk-options__label"
  }, [_vm._v("Selected: " + _vm._s(_vm.selectedRowsLength))]), _c('div', {
    staticClass: "bulk-options__actions"
  }, [_c('sygni-rect-button', {
    staticClass: "bulk-options__action outline disabled",
    on: {
      "click": _vm.clearTableSelection
    }
  }, [_vm._v("Unselect all")]), _c('div', {
    ref: "bulkOptionsDropdown",
    class: ['dropdown', _vm.accountingDateOpened ? 'dropdown--opened' : ''],
    attrs: {
      "id": "bulkOptionsPopover"
    }
  }, [_c('div', {
    staticClass: "dropdown__placeholder"
  }, [_c('p', {
    staticClass: "dropdown__placeholder-text"
  }, [_vm._v("Actions")]), _c('div', {
    staticClass: "dropdown__placeholder-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('ARROW'),
      "alt": "Dropdown"
    }
  })])]), _c('b-popover', {
    attrs: {
      "target": "bulkOptionsPopover",
      "customClass": "popover",
      "disabled": _vm.accountingDateOpened,
      "boundaryPadding": "0",
      "boundary": "viewport",
      "placement": "bottom",
      "triggers": "hover"
    }
  }, [_c('div', {
    staticClass: "popover__inner"
  }, [_vm.activeUserData.role.paymentManager ? _c('div', {
    staticClass: "popover__option popover__option--block"
  }, [_c('div', {
    staticClass: "popover__option-text"
  }, [_vm._v("Set payment status")]), _c('sygni-select', {
    attrs: {
      "options": (_vm$dictionaries = _vm.dictionaries) !== null && _vm$dictionaries !== void 0 && _vm$dictionaries.paymentStatuses ? _vm.dictionaries.paymentStatuses : [],
      "placeholder": _vm.bulkPaymentStatusPlaceholder
    },
    model: {
      value: _vm.bulkPaymentStatus,
      callback: function callback($$v) {
        _vm.bulkPaymentStatus = $$v;
      },
      expression: "bulkPaymentStatus"
    }
  })], 1) : _vm._e(), _vm.onlyAccountingDateEditableDocuments ? _c('div', {
    staticClass: "popover__option popover__option--block"
  }, [_c('div', {
    staticClass: "popover__option-text"
  }, [_vm._v("Set accounting date")]), _c('sygni-date-picker', {
    staticClass: "border-collapse",
    attrs: {
      "label": ""
    },
    on: {
      "open": _vm.openAccountingDateModal,
      "close": _vm.closeAccountingDateModal
    },
    model: {
      value: _vm.accountingDate,
      callback: function callback($$v) {
        _vm.accountingDate = $$v;
      },
      expression: "accountingDate"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "popover__option",
    on: {
      "click": _vm.downloadMultipleDocuments
    }
  }, [_c('p', {
    staticClass: "popover__option-text"
  }, [_vm._v("Download")]), _c('div', {
    staticClass: "popover__option-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('DOWNLOAD'),
      "alt": "Download"
    }
  })])]), _vm.onlyBookableDocuments ? _c('div', {
    staticClass: "popover__option",
    on: {
      "click": function click($event) {
        return _vm.bulkDocumentBook(null, '');
      }
    }
  }, [_c('div', {
    staticClass: "popover__option-text"
  }, [_vm._v("Book documents")]), _c('div', {
    staticClass: "popover__option-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('BOOK'),
      "alt": ""
    }
  })])]) : _vm._e(), _c('div', {
    staticClass: "popover__option",
    on: {
      "click": _vm.exportCsv
    }
  }, [_c('p', {
    staticClass: "popover__option-text"
  }, [_vm._v("Export to CSV")]), _c('div', {
    staticClass: "popover__option-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('DOWNLOAD'),
      "alt": "Download CSV"
    }
  })])]), _vm.onlyPaymentPackages ? _c('div', {
    staticClass: "popover__option",
    on: {
      "click": function click($event) {
        return _vm.enableBankAccountModal();
      }
    }
  }, [_c('p', {
    staticClass: "popover__option-text"
  }, [_vm._v("Generate payment package")]), _c('div', {
    staticClass: "popover__option-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('WALLET'),
      "alt": "Generate payment package"
    }
  })])]) : _vm._e(), _vm.showBulkSendToApproval ? _c('div', {
    staticClass: "popover__option",
    on: {
      "click": _vm.bulkDocumentSendToApproval
    }
  }, [_c('p', {
    staticClass: "popover__option-text"
  }, [_vm._v("Approve documents")]), _c('div', {
    staticClass: "popover__option-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('APPROVE 2'),
      "alt": "Approve documents"
    }
  })])]) : _vm._e(), _vm.onlyWaitingForApproval ? _c('div', {
    staticClass: "popover__option",
    on: {
      "click": _vm.bulkDocumentApproval
    }
  }, [_c('p', {
    staticClass: "popover__option-text"
  }, [_vm._v("Approve documents")]), _c('div', {
    staticClass: "popover__option-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('APPROVE 2'),
      "alt": "Approve documents"
    }
  })])]) : _vm._e(), _vm.onlyWaitingForRejection ? _c('div', {
    staticClass: "popover__option",
    on: {
      "click": _vm.enableRejectionModal
    }
  }, [_c('p', {
    staticClass: "popover__option-text"
  }, [_vm._v("Reject documents")]), _c('div', {
    staticClass: "popover__option-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('REJECT 2'),
      "alt": "Reject documents"
    }
  })])]) : _vm._e(), _vm.onlyDeletableDocuments ? _c('div', {
    staticClass: "popover__option",
    on: {
      "click": _vm.bulkDocumentDeletion
    }
  }, [_c('p', {
    staticClass: "popover__option-text"
  }, [_vm._v("Delete documents")]), _c('div', {
    staticClass: "popover__option-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('DELETE'),
      "alt": "Delete documents"
    }
  })])]) : _vm._e()])])], 1)], 1), Object.entries(_vm.selectedTotals).length ? _c('div', {
    staticClass: "bulk-options__summary"
  }, [_c('p', {
    staticClass: "bulk-options__label"
  }, [_vm._v("Total amount:")]), _c('div', {
    staticClass: "bulk-options__prices"
  }, _vm._l(Object.entries(_vm.selectedTotals), function (_ref) {
    var key = _ref[0],
        value = _ref[1];
    return _c('p', {
      key: key
    }, [_vm._v(_vm._s(_vm._f("numberFormat")(value, 2)))]);
  }), 0), _c('div', {
    staticClass: "bulk-options__currencies"
  }, _vm._l(Object.entries(_vm.selectedTotals), function (_ref2) {
    var key = _ref2[0];
    return _c('p', {
      key: key
    }, [_vm._v(_vm._s(key))]);
  }), 0)]) : _vm._e()])], 1), _c('b-table', _vm._g(_vm._b({
    ref: "accountingTable",
    staticClass: "sygni-b-table",
    attrs: {
      "fields": _vm.tableFields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "no-local-sorting": true,
      "per-page": _vm.perPage,
      "busy": _vm.isBusy
    },
    on: {
      "sort-changed": _vm.onSortChange,
      "row-clicked": _vm.onRowClicked,
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "head(selected)",
      fn: function fn() {
        return [_c('sygni-checkbox', {
          staticClass: "secondary",
          model: {
            value: _vm.selectAllRowsBoolean,
            callback: function callback($$v) {
              _vm.selectAllRowsBoolean = $$v;
            },
            expression: "selectAllRowsBoolean"
          }
        })];
      },
      proxy: true
    }, _vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function fn(rowData) {
          return [_vm._t(slot, null, null, rowData)];
        }
      };
    }), {
      key: "cell(selected)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "table-checkbox",
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.toggleTableRow(_vm.bulkRows[rowData.index], rowData.index);
            }
          }
        }, [_c('sygni-checkbox', {
          staticClass: "secondary",
          model: {
            value: _vm.bulkRows[rowData.index].value,
            callback: function callback($$v) {
              _vm.$set(_vm.bulkRows[rowData.index], "value", $$v);
            },
            expression: "bulkRows[rowData.index].value"
          }
        })], 1)];
      }
    }, {
      key: "cell(documentNumber)",
      fn: function fn(rowData) {
        return [_c('span', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: rowData.item.documentNumber,
            expression: "rowData.item.documentNumber",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("trimString")(rowData.item.documentNumber, 15)))])];
      }
    }, {
      key: "cell(fileName)",
      fn: function fn(rowData) {
        return [_c('span', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: rowData.item.fileName,
            expression: "rowData.item.fileName",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("trimString")(rowData.item.fileName, 10)))])];
      }
    }, {
      key: "cell(counterPartyName)",
      fn: function fn(rowData) {
        return [_c('span', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: rowData.item.counterPartyName,
            expression: "rowData.item.counterPartyName",
            modifiers: {
              "hover": true
            }
          }]
        }, [_vm._v(_vm._s(_vm._f("trimString")(rowData.item.counterPartyName, 35)))])];
      }
    }, {
      key: "cell(saleDate)",
      fn: function fn(rowData) {
        return [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("dateWithoutTime")(rowData.item.saleDate)))])];
      }
    }, {
      key: "cell(paymentDate)",
      fn: function fn(rowData) {
        return [rowData.item.paymentStatus == 'paid' || rowData.item.paymentStatus == 'scheduled' ? _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("dateWithoutTime")(rowData.item.paymentDate)))]) : _c('div', {
          class: ['payment-date', rowData.item.paymentDate && new Date(rowData.item.paymentDate) < new Date() ? 'payment-date--overdue' : '']
        }, [_c('p', {
          staticClass: "payment-date__label"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("dateWithoutTime")(rowData.item.paymentDate)))]), _c('span', {
          staticClass: "payment-date__status"
        }, [_vm._v("overdue")])])])];
      }
    }, {
      key: "cell(additionDate)",
      fn: function fn(rowData) {
        return [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("dateWithoutTime")(rowData.item.additionDate)))])];
      }
    }, {
      key: "cell(paymentStatus)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "table__status d-flex justify-content-center align-items-center"
        }, [_c('sygni-rounded-button', {
          class: ['outline round', _vm.getPaymentStatusClassName(rowData.item.paymentStatus)],
          attrs: {
            "hoverable": false
          }
        }, [_vm._v(_vm._s(_vm._f("snakeCaseToTitleCase")(rowData.item.paymentStatus)))])], 1)];
      }
    }, {
      key: "cell(grossAmount)",
      fn: function fn(rowData) {
        return [_c('p', {
          staticClass: "table-price-cell"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.math.number(_vm.math.bignumber(Number(rowData.item.grossAmount).toFixed(2))), 2)))])])];
      }
    }, {
      key: "cell(flag)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "table-cell",
          on: {
            "click": function click($event) {
              $event.stopPropagation();
            }
          }
        }, [_c('sygni-flag-picker', {
          attrs: {
            "document": rowData.item
          },
          on: {
            "openFlagManagementModal": function openFlagManagementModal($event) {
              return _vm.$emit('openFlagManagementModal');
            },
            "changeFlag": _vm.refreshTable
          }
        })], 1)];
      }
    }, {
      key: "cell(status)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "table__status d-flex justify-content-center align-items-center"
        }, [_c('sygni-rounded-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: _vm.getTooltipStatusMessage(rowData.item),
            expression: "getTooltipStatusMessage(rowData.item)",
            modifiers: {
              "hover": true
            }
          }],
          class: ['outline round', _vm.getDocumentStatusClassName(rowData.item.status)],
          attrs: {
            "hoverable": false
          }
        }, [_vm._v(_vm._s(_vm.statusText(rowData.item.status)))])], 1)];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "td__actions",
          on: {
            "click": function click($event) {
              $event.stopPropagation();
            }
          }
        }, [_c('div', {
          staticClass: "td__actions-elements"
        }, _vm._l(_vm.formattedActionItems, function (action) {
          return _c('div', {
            key: action.id,
            class: ['action', action.key == 'delete' ? 'action--delete' : '', rowData.item.errors && rowData.item.errors.length > 0 && (action.key == 'book' || action.key == 'book-readonly') ? 'action--disabled' : '']
          }, [_vm.getRowDataActionItems(rowData.item.actions).includes(action.key) && action.key == 'edit' || _vm.getRowDataActionItems(rowData.item.actions).includes('preview') && action.key == 'edit' ? _c('div', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              value: 'Open preview',
              expression: "'Open preview'",
              modifiers: {
                "hover": true
              }
            }],
            staticClass: "action__el",
            on: {
              "click": function click($event) {
                return _vm.editDocument(rowData);
              }
            }
          }, [_c('img', {
            attrs: {
              "src": _vm._f("getIcon")('Fund')
            }
          })]) : _vm._e(), _vm.getRowDataActionItems(rowData.item.actions).includes(action.key) && action.key == 'generate-xml-payment' ? _c('div', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              value: 'Generate payment package',
              expression: "'Generate payment package'",
              modifiers: {
                "hover": true
              }
            }],
            staticClass: "action__el",
            on: {
              "click": function click($event) {
                return _vm.enableBankAccountModal(rowData.item);
              }
            }
          }, [_c('img', {
            attrs: {
              "src": _vm._f("getIcon")('WALLET'),
              "alt": "Generate payment package"
            }
          })]) : _vm._e(), _vm.getRowDataActionItems(rowData.item.actions).includes(action.key) && action.key == 'send-to-approval' ? _c('div', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              value: _vm.checkShouldSend(rowData.item) ? 'Send for Approval' : 'Approve',
              expression: "checkShouldSend(rowData.item) ? 'Send for Approval' : 'Approve'",
              modifiers: {
                "hover": true
              }
            }],
            staticClass: "action__el",
            on: {
              "click": function click($event) {
                return _vm.sendDocument(rowData.item);
              }
            }
          }, [_vm.checkShouldSend(rowData.item) ? _c('img', {
            attrs: {
              "src": _vm._f("getIcon")('APPROVE')
            }
          }) : _c('img', {
            attrs: {
              "src": _vm._f("getIcon")('APPROVE 2')
            }
          })]) : _vm._e(), _vm.getRowDataActionItems(rowData.item.actions).includes(action.key) && (action.key == 'book' || action.key == 'book-readonly') ? _c('div', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              value: _vm.getErrors(rowData.item) ? _vm.getErrors(rowData.item) : 'Book',
              expression: "getErrors(rowData.item) ? getErrors(rowData.item) : 'Book'",
              modifiers: {
                "hover": true
              }
            }],
            staticClass: "action__el",
            on: {
              "click": function click($event) {
                return _vm.bulkDocumentBook([rowData.item.id], action.key);
              }
            }
          }, [_c('img', {
            attrs: {
              "src": _vm._f("getIcon")('BOOK')
            }
          })]) : _vm._e(), _vm.getRowDataActionItems(rowData.item.actions).includes(action.key) && action.key == 'download' ? _c('div', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              value: 'Download',
              expression: "'Download'",
              modifiers: {
                "hover": true
              }
            }],
            staticClass: "action__el",
            on: {
              "click": function click($event) {
                return _vm.downloadFileByUrl(rowData.item);
              }
            }
          }, [_c('img', {
            attrs: {
              "src": _vm._f("getIcon")('DOWNLOAD')
            }
          })]) : _vm._e(), _vm.getRowDataActionItems(rowData.item.actions).includes(action.key) && action.key == 'delete' ? _c('div', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              value: 'Delete',
              expression: "'Delete'",
              modifiers: {
                "hover": true
              }
            }],
            staticClass: "action__el action__el--delete",
            on: {
              "click": function click($event) {
                return _vm.deleteRow(rowData.item);
              }
            }
          }, [_c('svg', {
            attrs: {
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "15.605",
              "height": "18",
              "viewBox": "0 0 15.605 18"
            }
          }, [_c('path', {
            staticStyle: {
              "fill": "currentColor"
            },
            attrs: {
              "d": "M8.188.529a1.8 1.8 0 0 0-1.273.528l-.672.672H3.389a1.807 1.807 0 0 0-1.351.576 1.829 1.829 0 0 0-.453 1.224 1.83 1.83 0 0 0 .453 1.225 1.717 1.717 0 0 0 .749.48v1.895a.6.6 0 1 0 1.2 0V5.33h10.8v11.4a.586.586 0 0 1-.6.6h-9.6a.586.586 0 0 1-.6-.6v-6a.6.6 0 1 0-1.2 0v6a1.809 1.809 0 0 0 1.8 1.8h9.6a1.809 1.809 0 0 0 1.8-1.8V5.235a1.717 1.717 0 0 0 .75-.481 1.83 1.83 0 0 0 .453-1.225 1.829 1.829 0 0 0-.453-1.224 1.807 1.807 0 0 0-1.351-.576h-2.853l-.673-.672a1.8 1.8 0 0 0-1.272-.528zm0 1.2h2.4a.6.6 0 0 1 .424.176l.848.848a.6.6 0 0 0 .424.176h3.1a.533.533 0 0 1 .455.174.685.685 0 0 1 0 .853.533.533 0 0 1-.455.174h-12a.533.533 0 0 1-.455-.174.685.685 0 0 1 0-.853.533.533 0 0 1 .455-.174h3.1a.6.6 0 0 0 .424-.176l.848-.848a.6.6 0 0 1 .432-.176zM6.978 7.72a.6.6 0 0 0-.591.609v6a.6.6 0 1 0 1.2 0v-6a.6.6 0 0 0-.609-.609zm2.4 0a.6.6 0 0 0-.591.609v6a.6.6 0 0 0 1.2 0v-6a.6.6 0 0 0-.609-.609zm2.4 0a.6.6 0 0 0-.591.609v6a.6.6 0 0 0 1.2 0v-6a.6.6 0 0 0-.609-.609z",
              "transform": "translate(-1.585 -.529)"
            }
          })])]) : _vm._e()]);
        }), 0), _c('div', {
          staticClass: "td__actions-toggle",
          on: {
            "click": function click($event) {
              $event.stopPropagation();
            }
          }
        }, [_c('div', {
          staticClass: "action"
        }, [_c('div', {
          staticClass: "action__el"
        }, [_c('img', {
          attrs: {
            "src": _vm._f("getIcon")('DOTS')
          }
        })])])])])];
      }
    }, {
      key: "custom-foot",
      fn: function fn(data) {
        return [_c('tr', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.showDocument,
            expression: "!showDocument"
          }]
        }, _vm._l(data.columns, function (i) {
          var _data$fields, _data$fields2, _data$fields3, _data$fields4, _data$fields5;

          return _c('td', {
            key: i,
            class: "td-".concat(data.fields[i - 1].key)
          }, [((_data$fields = data.fields[i - 1]) === null || _data$fields === void 0 ? void 0 : _data$fields.key) == 'documentNumber' ? _c('div', [_c('p', [_vm._v("Total invoices:")])]) : _vm._e(), ((_data$fields2 = data.fields[i - 1]) === null || _data$fields2 === void 0 ? void 0 : _data$fields2.key) == 'fileName' ? _c('div', [_c('p', [_vm._v(_vm._s(_vm.items.length ? _vm.items.length : 0))])]) : _vm._e(), ((_data$fields3 = data.fields[i - 1]) === null || _data$fields3 === void 0 ? void 0 : _data$fields3.key) == 'paymentDate' ? _c('div', [_c('p', [_vm._v("Total amount:")])]) : _vm._e(), ((_data$fields4 = data.fields[i - 1]) === null || _data$fields4 === void 0 ? void 0 : _data$fields4.key) == 'grossAmount' ? _c('div', {
            staticClass: "text-right"
          }, _vm._l(Object.entries(_vm.totals), function (_ref3) {
            var key = _ref3[0],
                value = _ref3[1];
            return _c('p', {
              key: key,
              staticClass: "text-nowrap"
            }, [_vm._v(_vm._s(_vm._f("numberFormat")(value, 2)))]);
          }), 0) : _vm._e(), ((_data$fields5 = data.fields[i - 1]) === null || _data$fields5 === void 0 ? void 0 : _data$fields5.key) == 'currency' ? _c('div', _vm._l(Object.entries(_vm.totals), function (_ref4) {
            var key = _ref4[0];
            return _c('p', {
              key: key
            }, [_vm._v(_vm._s(key))]);
          }), 0) : _vm._e()]);
        }), 0)];
      }
    }], null, true)
  }, 'b-table', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners)), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isBusy,
      expression: "isBusy"
    }],
    staticClass: "text-center spinner"
  }, [_c('b-spinner', {
    staticClass: "align-middle mr-3"
  }), _c('strong', [_vm._v("Loading...")])], 1)], 1), _c('div', {
    staticClass: "accounting-table__preview"
  }, [_c('preview-doc', {
    attrs: {
      "selectedDocument": _vm.selectedDocument,
      "showDocument": _vm.showDocument,
      "checkViewport": false,
      "contextData": _vm.contextData,
      "activeDocument": _vm.activeDocument,
      "enabledActions": (_vm$activeDocument = _vm.activeDocument) === null || _vm$activeDocument === void 0 ? void 0 : _vm$activeDocument.actions,
      "shouldSend": _vm.shouldSend
    },
    on: {
      "edit": _vm.editDocument,
      "send": _vm.sendDocument,
      "prev": _vm.prevDocument,
      "next": _vm.nextDocument,
      "download": _vm.downloadDocument,
      "close": _vm.closeDocument,
      "delete": function _delete($event) {
        return _vm.deleteRow(_vm.activeDocument);
      },
      "generatePaymentPackage": function generatePaymentPackage($event) {
        return _vm.enableBankAccountModal(_vm.activeDocument);
      },
      "openFlagManagementModal": function openFlagManagementModal($event) {
        return _vm.$emit('openFlagManagementModal');
      },
      "changeFlag": _vm.refreshTable,
      "bookDocument": function bookDocument($event) {
        return _vm.bulkDocumentBook([_vm.activeDocument.id], 'book');
      }
    }
  })], 1), _vm.showBankAccountModal ? _c('sygni-modal', {
    attrs: {
      "isLoading": _vm.isPaymentPackageLoading,
      "header": "Payment Package Details",
      "cancelText": "Cancel",
      "confirmText": "Generate package",
      "size": "medium"
    },
    on: {
      "cancel": _vm.closeBankAccountModal,
      "confirm": _vm.generatePaymentPackage,
      "close": _vm.closeBankAccountModal
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('div', {
          staticClass: "sygni-modal-form"
        }, [_c('p', {
          staticClass: "sygni-modal-form__header"
        }, [_vm._v("1. Make payment from the bank account:")]), _c('div', {
          staticClass: "sygni-modal-form__group"
        }, [_c('sygni-select', {
          staticClass: "secondary",
          attrs: {
            "options": _vm.formattedBankAccounts,
            "clear-on-select": false,
            "validation": _vm.$v.selectedBankAccount,
            "label": "Bank account"
          },
          model: {
            value: _vm.selectedBankAccount,
            callback: function callback($$v) {
              _vm.selectedBankAccount = $$v;
            },
            expression: "selectedBankAccount"
          }
        }), _c('div', {
          staticClass: "sygni-modal-form__row"
        }, [_c('div', {
          staticClass: "sygni-modal-form__row-data"
        }, [_c('label', {
          staticClass: "debtor-data mb-3"
        }, [_c('span', {
          staticClass: "debtor-data__title"
        }, [_vm._v(" NAME: ")]), _vm._v(" " + _vm._s(_vm.selectedBankName) + " ")]), _c('label', {
          staticClass: "debtor-data mb-3"
        }, [_c('span', {
          staticClass: "debtor-data__title"
        }, [_vm._v(" CURRENCY: ")]), _vm._v(" " + _vm._s(_vm.selectedBankCurrency) + " ")])]), _c('div', {
          staticClass: "sygni-modal-form__row-checkbox"
        }, [_c('sygni-checkbox', {
          staticClass: "secondary",
          attrs: {
            "clickableLabel": true
          },
          model: {
            value: _vm.isSepa,
            callback: function callback($$v) {
              _vm.isSepa = $$v;
            },
            expression: "isSepa"
          }
        }, [_vm._v("SEPA")])], 1)])], 1)]), _c('div', {
          staticClass: "sygni-modal-form"
        }, [_c('p', {
          staticClass: "sygni-modal-form__header"
        }, [_vm._v("2. Execute payment package:")]), _c('div', {
          staticClass: "sygni-modal-form__group"
        }, [_c('div', {
          staticClass: "sygni-modal-form__radios"
        }, [_c('sygni-radio', {
          attrs: {
            "group": 'customPaymentDate',
            "radioValue": true,
            "label": "on a custom date"
          },
          model: {
            value: _vm.useCustomPaymentDate,
            callback: function callback($$v) {
              _vm.useCustomPaymentDate = $$v;
            },
            expression: "useCustomPaymentDate"
          }
        }), _c('sygni-radio', {
          attrs: {
            "group": 'customPaymentDate',
            "radioValue": false,
            "label": "at date of payment of each document"
          },
          model: {
            value: _vm.useCustomPaymentDate,
            callback: function callback($$v) {
              _vm.useCustomPaymentDate = $$v;
            },
            expression: "useCustomPaymentDate"
          }
        })], 1), _c('sygni-date-picker', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.useCustomPaymentDate,
            expression: "useCustomPaymentDate"
          }],
          attrs: {
            "format": "YYYY-MM-DD",
            "validation": _vm.$v.customPaymentDate
          },
          model: {
            value: _vm.customPaymentDate,
            callback: function callback($$v) {
              _vm.customPaymentDate = $$v;
            },
            expression: "customPaymentDate"
          }
        })], 1)]), _c('div', {
          staticClass: "sygni-modal-form"
        }, [_c('p', {
          staticClass: "sygni-modal-form__header"
        }, [_vm._v("3. Payment package name:")]), _c('div', {
          staticClass: "sygni-modal-form__group"
        }, [_c('div', {
          staticClass: "sygni-modal-form__inputs"
        }, [_c('sygni-input', {
          attrs: {
            "label": "",
            "placeholder": _vm.defaultPaymentPackageName
          },
          model: {
            value: _vm.customPackageName,
            callback: function callback($$v) {
              _vm.customPackageName = $$v;
            },
            expression: "customPackageName"
          }
        })], 1)])])];
      },
      proxy: true
    }], null, false, 963482204)
  }) : _vm._e(), _c('sygni-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showRejectionModal,
      expression: "showRejectionModal"
    }],
    attrs: {
      "cancelText": "Cancel",
      "confirm": "Reject document",
      "isLoading": _vm.isRejectionModalLoading,
      "header": "Why are you rejecting these documents?"
    },
    on: {
      "cancel": _vm.closeRejectionModal,
      "confirm": _vm.bulkDocumentRejection,
      "close": _vm.closeRejectionModal
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('sygni-text-area', {
          staticClass: "secondary",
          attrs: {
            "label": "REASON"
          },
          model: {
            value: _vm.rejectionNote,
            callback: function callback($$v) {
              _vm.rejectionNote = $$v;
            },
            expression: "rejectionNote"
          }
        })];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }