var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "accounting-periods-table"
  }, [_c('sygni-loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('div', {
    staticClass: "range-switcher"
  }, [_c('div', {
    staticClass: "range-switcher__btn",
    on: {
      "click": _vm.prevHandler
    }
  }, [_c('div', {
    staticClass: "range-switcher__btn-icon"
  }, [_c('img', {
    attrs: {
      "alt": "",
      "src": _vm._f("getIcon")('DB ARROW LEFT')
    }
  })])]), _c('div', {
    staticClass: "range-switcher__value"
  }, [_vm._v(" " + _vm._s(_vm.years[0]) + " - " + _vm._s(_vm.years[3]) + " ")]), _c('div', {
    staticClass: "range-switcher__btn",
    on: {
      "click": _vm.nextHandler
    }
  }, [_c('div', {
    staticClass: "range-switcher__btn-icon"
  }, [_c('img', {
    attrs: {
      "alt": "Next",
      "src": _vm._f("getIcon")('DB ARROW RIGHT')
    }
  })])])]), _c('simplebar', {
    staticClass: "accounting-periods-table__wrapper",
    attrs: {
      "data-simplebar-auto-hide": "false"
    }
  }, [_c('table', {
    staticClass: "table"
  }, [_c('thead', {
    staticClass: "accounting-periods-table__thead"
  }, [_c('tr', [_c('th', [_c('div', {
    staticClass: "accounting-periods-table__header"
  }, [_c('p', [_vm._v("Month")])])]), _vm._l(_vm.years, function (field, index) {
    return _c('th', {
      key: index
    }, [_c('div', {
      staticClass: "accounting-periods-table__header text-center"
    }, [_c('p', [_vm._v(_vm._s(field))])])]);
  })], 2)]), _c('tbody', _vm._l(_vm.months, function (row) {
    return _c('tr', [_c('td', [_c('div', {
      staticClass: "cell name"
    }, [_c('span', [_vm._v(_vm._s(row.name))])])]), _vm._l(_vm.years, function (item) {
      return _c('td', [_c('div', {
        staticClass: "cell"
      }, [_c('sygni-rounded-button', {
        class: ['outline round', _vm.isPeriodOpened(item, row) ? 'primary' : 'danger'],
        attrs: {
          "hoverable": false
        }
      }, [_vm._v(_vm._s(_vm.isPeriodOpened(item, row) ? 'Opened' : 'Closed'))]), _c('sygni-link-button', {
        attrs: {
          "type": "simple"
        },
        on: {
          "click": function click($event) {
            return _vm.handlePeriod(item, row);
          }
        }
      }, [_vm._v(_vm._s(_vm.isPeriodOpened(item, row) ? 'Close period' : 'Open period'))])], 1)]);
    })], 2);
  }), 0), _c('thead', [_c('tr', [_c('th', [_c('div', {
    staticClass: "accounting-periods-table__header"
  }, [_c('p', [_vm._v("Month")])])]), _vm._l(_vm.years, function (field, index) {
    return _c('th', {
      key: index
    }, [_c('div', {
      staticClass: "accounting-periods-table__header text-center"
    }, [_c('p', [_vm._v(_vm._s(field))])])]);
  })], 2)])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }