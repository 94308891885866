

































































import Vue from 'vue';
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator';
import SygniLoader from '@/components/layout/SygniLoader.vue';
import SygniRoundedButton from '@/components/buttons/SygniRoundedButton.vue';
import SygniLinkButton from '@/components/buttons/SygniLinkButton.vue';
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';
import moment from 'moment';

@Component({
  components: { SygniRoundedButton, SygniLinkButton, SygniLoader, simplebar }
})
export default class AccountingPeriodsTable extends Vue {
  
  @Prop() tableSections: any;
  @Prop() editMode: boolean;
  @Prop() selectedCol: number;

  periods: any = [];
  isLoading: boolean = false;
  years: any = []
  months: any = [
    { index: 1, name: 'January' },
    { index: 2, name: 'February' },
    { index: 3, name: 'March' },
    { index: 4, name: 'April' },
    { index: 5, name: 'May' },
    { index: 6, name: 'June' },
    { index: 7, name: 'July' },
    { index: 8, name: 'August' },
    { index: 9, name: 'September' },
    { index: 10, name: 'October' },
    { index: 11, name: 'November' },
    { index: 12, name: 'December' },
  ]

  isPeriodOpened(year: any, month: any) {
    return !this.closedPeriods?.find((el: any) => parseInt(el.year) === year && month.index === parseInt(el.month))
  }

  loadPeriods() {
    const currentYear = parseInt(moment().format('YYYY'))
    this.years = [currentYear, currentYear + 1, currentYear + 2, currentYear + 3]
  }

  prevHandler() {
    const firstYear = parseInt(this.years[0])

    this.years = [firstYear - 1, firstYear, firstYear + 1, firstYear + 2]
  }

  nextHandler() {
    const lastYear = parseInt(this.years[3])

    this.years = [lastYear - 2, lastYear - 1, lastYear, lastYear + 1]
  }

  get closedPeriods () {
    return this.periods?.filter((el: any) => el.status === 'closed').map((el: any) => {
      return { year: moment(el.date).format('YYYY'), month: moment(el.date).format('M') }
    })
  }

  async handlePeriod(year: any, month: any) {
    if (this.isPeriodOpened(year, month)) {
      await this.closePeriod(year, month.index)
    } else {
      await this.openPeriod(year, month.index)
    }
  }

  async openPeriod(year: any, month: number) {
    this.isLoading = true;
    try {
      await this.$store.dispatch('accounting/openAccountingPeriod', { year, month })
      await this.getAccountingPeriods()
    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Błąd',
        text: errorMessage
      })
    }
    this.isLoading = false;
  }

  async closePeriod(year: any, month: number) {
    this.isLoading = true;
    try {
      await this.$store.dispatch('accounting/closeAccountingPeriod', { year, month })
      await this.getAccountingPeriods()
    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Błąd',
        text: errorMessage
      })
    }
    this.isLoading = false;
  }

  async getAccountingPeriods() {
    try {
      const resp = await this.$store.dispatch('accounting/getAccountingPeriods')
      this.periods = resp;
    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Błąd',
        text: errorMessage
      })
    }
  }

  async mounted() {
    this.isLoading = true;
    this.loadPeriods();
    await this.getAccountingPeriods();
    this.isLoading = false;
  }
}
