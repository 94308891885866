














































import Vue from 'vue'
import Component, {mixins} from 'vue-class-component'
import { Prop } from "vue-property-decorator";
import {SelectOption} from "@/store/types";
import SygniInputError from "@/components/inputs/SygniInputError.vue";
import {SygniValidation} from "@/shared/mixins/ValidationMixin";
import Multiselect from 'vue-multiselect';
import SygniLinkButton from '@/components/buttons/SygniLinkButton.vue';
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import SygniFlag from '@/components/layout/SygniFlag.vue';

@Component({
  components: {SygniCheckbox, SygniInputError, Multiselect, SygniLinkButton, SygniFlag}
})
export default class SygniMultiSelect extends mixins(Vue, SygniValidation) {

  @Prop({default: false}) selectAll: boolean;
  @Prop() label!: String;
  @Prop() value!: Array<any>;
  @Prop() options!: Array<{ label: string, value: string, color: string }>;
  @Prop({default: 'label'}) optionsLabel!: string;
  @Prop({default: false}) searchable!: string;
  isFiltered: Boolean = false;

  get optionValue(): SelectOption[] {
    if(this.options?.length > 0 && this.value?.length > 0){
      return this.options.map( option => {
        if(this.value.includes(option.value)) return option;
      }).filter( option => option !== undefined);

    } else {
      return [];
    }
  }

  handleSearch() {
    this.isFiltered = !!(this.$refs.multiselect as any).search.length;
  }

  get toggleAllText() {
    return this.value.length < this.options.length ? 'Select all' : 'Deselect all';
  }

  isSelected(option: SelectOption): boolean {
    return this.optionValue.includes(option);
  }

  handleInput(option: SelectOption): any {
    if(!option){
      this.$emit('input', null);
    } else {
      this.$emit('input', [option].map(option => option.value));
    }
  }

  toggleAll() {
    this.$emit('toggleAll');
  }

  mounted() {
  }

  clear(event:any){
    event;
  }

  removeKey(object: any, propName: string){
    return this.$options.filters.removeKey(object, propName);
  }
}


